import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { User, UserSwitcherService } from 'taxtank-core';
import { JwtService } from 'taxtank-core/common';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard {
  constructor(private router: Router, private jwtService: JwtService, private userSwitcherService: UserSwitcherService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.check(state);
  }
  check(state: RouterStateSnapshot): boolean {
    // not logged in, redirect to login page
    if (!this.jwtService.getToken()) {
      this.router.navigate(['/login'], { fragment: state.url });
    }

    // set user email to sentry for better user identifying
    let email = this.jwtService.decode().username;
    if (this.userSwitcherService.get()) {
      email += `?${this.userSwitcherService.get()}`;
    }

    Sentry.setUser({ email: email });

    return true;
  }
}
