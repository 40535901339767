import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableSearchDirective } from '@shared/directives/table/table-search.directive';
import { DownloadDirective } from '@shared/directives/download/download.directive';
import { InputCurrencyFormatDirective } from '@shared/directives/input-currency-format/input-currency-format.directive';
import { NotificationsDirective } from './notifications.directive';
import { PreloaderDirective } from './preloader/preloader.directive';
import { AccessDirective, AccessDisableDirective } from './access';
import { FormGroupDirective } from './form/form-group/form-group.directive';
import { RolesDirective } from './roles/roles.directive';
import { PropertiesLimitDirective } from './properties-limit/properties-limit.directive';
import { ExportableDirective } from '@shared/directives/exportable/exportable.directive';
import { FormErrorScrollDirective } from '@shared/directives/form/form-error-scroll.directive';
import { InputTrimDirective } from '@shared/directives/input-trim/input-trim.directive';
import { StopPropagationDirective } from './stop-propagation/stop-propagation.directive';
import { FormSubmitDirective } from './form-submit/form-submit.directive';
import { CopyToClipboardDirective } from './copy-to-clipboard/copy-to-clipboard.directive';
import { PreventDefaultDirective } from './prevent-default/prevent-default.directive';
import { HorizontalScrollDirective } from './horizontal-scroll/horizontal-scroll.directive';
import { AutocompletePositionDirective } from '@shared/directives/autocomplete/autocomplete-position.directive';
import { DragNDropDirective } from './drag-n-drop/drag-n-drop.directive';
import { ConfirmDirective } from './confirm/confirm.directive';
import { OpenChatDirective } from './open-chat/open-chat.directive';
import { TrackDirective } from './analytics/track.directive';
import { ChartDirective } from './chart/chart.directive';
import { PropertyEquityPositionChartDirective } from './chart/property-equity-position-chart.directive';

// @TODO add here all existing directives
@NgModule({
  declarations: [
    TableSearchDirective,
    DownloadDirective,
    InputCurrencyFormatDirective,
    NotificationsDirective,
    PreloaderDirective,
    AccessDirective,
    AccessDisableDirective,
    FormGroupDirective,
    RolesDirective,
    PropertiesLimitDirective,
    ExportableDirective,
    FormErrorScrollDirective,
    InputTrimDirective,
    StopPropagationDirective,
    FormSubmitDirective,
    CopyToClipboardDirective,
    PreventDefaultDirective,
    HorizontalScrollDirective,
    AutocompletePositionDirective,
    DragNDropDirective,
    ConfirmDirective,
    OpenChatDirective,
    TrackDirective,
    PropertyEquityPositionChartDirective
  ],
  imports: [
    ChartDirective,
    CommonModule
  ],
  exports: [
    TableSearchDirective,
    DownloadDirective,
    InputCurrencyFormatDirective,
    NotificationsDirective,
    PreloaderDirective,
    AccessDirective,
    AccessDisableDirective,
    RolesDirective,
    FormGroupDirective,
    PropertiesLimitDirective,
    ExportableDirective,
    FormErrorScrollDirective,
    InputTrimDirective,
    StopPropagationDirective,
    FormSubmitDirective,
    CopyToClipboardDirective,
    PreventDefaultDirective,
    HorizontalScrollDirective,
    AutocompletePositionDirective,
    DragNDropDirective,
    ConfirmDirective,
    OpenChatDirective,
    TrackDirective,
    ChartDirective,
    PropertyEquityPositionChartDirective
  ]
})
export class DirectivesModule { }
