import { environment } from '@env/environment';

/**
 * Run google initialization
 */
export function googleInit() {
  // We're using google service for login only. We don't need it when we logged in or haven't googleClientId
  if (!environment['googleClientId'] || localStorage.getItem('userId')) {
    return;
  }

  const script = document.createElement('script');
  const scriptRef = document.getElementsByTagName('script')[1];

  script.async = true;
  script.defer = true;
  script.src = 'https://accounts.google.com/gsi/client';
  scriptRef.parentNode.insertBefore(script, scriptRef);
}
