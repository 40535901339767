import { NgModule } from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { ToastComponent } from './toast/toast.component';
import { MessageService as PrimeToastService } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { CarouselModule } from 'primeng/carousel';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { SkeletonModule } from 'primeng/skeleton';
import { CheckboxModule } from 'primeng/checkbox';
import { SidebarModule } from 'primeng/sidebar';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [
    ToastModule,
    ListboxModule,
    PasswordModule,
    CarouselModule,
    DividerModule,
    FileUploadModule,
    InputNumberModule,
    MultiSelectModule,
    SkeletonModule,
    CheckboxModule,
    SidebarModule,
    PanelModule,
    TableModule,
    ButtonModule,
  ],
  exports: [
    ToastComponent,
    ListboxModule,
    PasswordModule,
    DividerModule,
    CarouselModule,
    FileUploadModule,
    InputNumberModule,
    MultiSelectModule,
    SkeletonModule,
    CheckboxModule,
    SidebarModule,
    PanelModule,
    TableModule,
    ButtonModule,
  ],
  declarations: [
    ToastComponent
  ],
  providers: [
    PrimeToastService
  ]
})
export class PrimeModule { }
