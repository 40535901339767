import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSecurityComponent } from '@shared/component/account-security/account-security.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PreloaderModule, ValidationErrorModule } from '@shared/component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PasswordMatcherComponent } from '@shared/component/account-security/password-matcher/password-matcher.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PrimeModule } from '@shared/prime/prime.module';
import { PasswordInputModule } from '@shared/component/form-controls/input/password-input/password-input.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    AccountSecurityComponent,
    PasswordMatcherComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    ValidationErrorModule,
    PreloaderModule,
    DirectivesModule,
    PrimeModule,
    PasswordInputModule,
  ],
  exports: [
    AccountSecurityComponent,
    PasswordMatcherComponent
  ]
})
export class AccountSecurityModule { }
