import { environment } from '@env/environment';

/**
 * Init Google Tag Manager connection
 */
export function gtmInit() {
  // Do nothing when Google Tag Manager id is missing
  if (!environment.gtmId) {
    return;
  }

  (() => {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    const j = document.createElement('script');
    const f = document.getElementsByTagName('script')[0];

    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${environment.gtmId}`;
    f.parentNode.insertBefore(j, f);
  })();

  // create and append noscript for Google tag manager
  const noscript = document.createElement('noscript');

  const iframeContent = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=${environment.gtmId}"
          height="0" width="0" style="display:none;visibility:hidden">
  </iframe>`;

  noscript.append(iframeContent);
  document.body.prepend(noscript);
}
