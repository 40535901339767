import { PreloaderTypeEnum } from '@shared/component/preloader/preloader-type.enum';
import { UserRolesEnum } from 'taxtank-core/common';
import { ENDPOINTS, Endpoint, FinancialYear, TankTypeEnum, UserService } from 'taxtank-core';
import { Subject } from 'rxjs';
import { ChangeDetectorRef, Component, OnDestroy, inject } from '@angular/core';
import { ViewPortService } from '@shared/services/view-port.service';
import { environment } from '@env/environment';

/**
 * Abstract base component with common functionality for all components
 * Empty Component decorator is required for Angular lifecycle hooks
 * Compiler error: Class is using Angular features but is not decorated. Please add an explicit Angular decorator.
 */
@Component({ template: '' })
export abstract class BaseComponent implements OnDestroy {
  protected userService = inject(UserService);
  public viewPortService = inject(ViewPortService);
  protected changeDetectorRef = inject(ChangeDetectorRef);
  endpoints: { [key: string]: Endpoint } = ENDPOINTS;
  userRoles: typeof UserRolesEnum = UserRolesEnum;
  preloaderTypes: typeof PreloaderTypeEnum = PreloaderTypeEnum;
  userFinYear = new FinancialYear().year;
  currentUser = this.userService.getCacheFirst();
  tankTypes = TankTypeEnum;
  env = environment;

  /**
   * Subject keep component's subscriptions and unsubscribe when component destroyed
   * https://stackoverflow.com/questions/47152752/angular-subscribe-unsubscribe-best-practices
   * @TODO Alex: think about smarter universal solution (implement in services or something like this)
   */
  destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnDestroy(): void {
    // Unsubscribe from whatever used takeUntil(destroy$)
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.complete();
  }
}
