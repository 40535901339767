import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdleLogoutComponent } from './idle-logout.component';
import { NgIdleModule } from '@ng-idle/core';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [IdleLogoutComponent],
  imports: [
    CommonModule,
    NgIdleModule.forRoot(),
    MatCardModule,
  ],
  exports: [IdleLogoutComponent]
})
export class IdleLogoutModule { }
