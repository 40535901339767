import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from 'taxtank-core/common';

@Injectable({
  providedIn: 'root'
})
export class AnonymGuard {
  constructor(private router: Router, private jwtService: JwtService) {}
  canActivate() {
    return this.check();
  }
  private check(): boolean {
    if (this.jwtService.getToken()) {
      this.router.navigateByUrl(this.jwtService.isClient() ? '/client' : '/firm');
    }
    return true;
  }
}
