import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { environment } from '@env/environment';
import { AuthService, JwtService } from 'taxtank-core/common';
import { BaseComponent } from '@shared/component/base/base.component';

const TIMEOUT = 30;

@Component({
  selector: 'app-idle-logout',
  templateUrl: './idle-logout.component.html',
  styleUrls: ['./idle-logout.component.scss']
})
export class IdleLogoutComponent extends BaseComponent implements OnInit {
  countdown = 0;

  constructor(
    private idle: Idle,
    private authService: AuthService,
    private jwtService: JwtService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.jwtService.isLoggedInSubject.subscribe((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        return;
      }

      this.setUp();

      this.idle.onIdleEnd.subscribe(() => {
        this.countdown = 0;
        this.changeDetectorRef.detectChanges();
      });
      this.idle.onTimeout.subscribe(() => {
        this.authService.logoutFront();
      });

      this.idle.onTimeoutWarning.subscribe((countdown) => this.countdown = countdown);
      this.idle.watch();
    });
  }

  setUp(): void {
    this.idle.setIdle(environment.logoutTimeout);
    this.idle.setTimeout(TIMEOUT);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }
}
