import { environment } from '@env/environment';

/**
 * Run facebook initialization
 */
export function facebookInit() {
  // Do nothing when facebook app id is missing or user is logged in
  if (!environment['facebookAppId'] || localStorage.getItem('userId')) {
    return;
  }

  (window as any).fbAsyncInit = () => {
    // FB JavaScript SDK configuration and setup
    FB.init({
      appId: environment['facebookAppId'], // FB App ID
      status: true,
      cookie: true,  // Enable cookies to allow the server to access the session
      xfbml: true,  // Parse social plugins on this page
      version: 'v20.0' // Latest version as of now
    });
  };

  // Load the JavaScript SDK asynchronously
  (((d, s, id) => {
    let js = d.getElementsByTagName(s)[0];
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js['src'] = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }) (document, 'script', 'facebook-jssdk'));
}
