import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { environment } from '@env/environment';

/**
 * Guard to provide access to application demo page.
 * Should give access only for non-production environment
 */
@Injectable({
  providedIn: 'root'
})
export class DemoGuard  {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.check();
  }

  protected check(): boolean {
    if (!environment.production) {
      return true;
    }

    this.router.navigateByUrl('/login');
    return false;
  }
}
