import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineChartComponent } from './line-chart/line-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseChartComponent } from './base-chart/base-chart.component';
import { ChartDirective } from '@shared/directives/chart/chart.directive';

@NgModule({
  declarations: [
    LineChartComponent,
    PieChartComponent,
    BarChartComponent,
    BaseChartComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    ChartDirective
  ],
  exports: [
    LineChartComponent,
    PieChartComponent,
    BarChartComponent,
  ],
})
export class ChartsModule { }
