import { Injectable } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

/**
 * Service to work with browser viewports
 */
@Injectable({
  providedIn: 'root'
})
export class ViewPortService {
  isMobile: boolean;
  isDesktop: boolean;

  constructor(
    private media: MediaMatcher,
  ) {
    const mediaQueryList: MediaQueryList = media.matchMedia('(max-width: 767px)');
    const desktopMediaQueryList: MediaQueryList = media.matchMedia('(min-width: 1200px)');
    this.isMobile = mediaQueryList.matches;
    this.isDesktop = desktopMediaQueryList.matches;
    mediaQueryList.addEventListener('change', () => this.isMobile = mediaQueryList.matches);
    desktopMediaQueryList.addEventListener('change', () => this.isDesktop = desktopMediaQueryList.matches);
  }
}
