<!-- @TODO implement smarter solution for validation errors -->
<ng-container *ngIf="errors" [ngSwitch]="true">
  <span *ngIf="errors['unique']">Already exists</span>
  <span *ngIf="errors['noneOf']">This value already used</span>
  <span *ngIf="errors['email']">Email format is wrong</span>
  <span *ngIf="errors['emailIsUsed']">Email already used</span>
  <span *ngIf="errors['abnIsUsed']">ABN already used</span>
  <span *ngIf="errors['tanIsUsed']">TAN already used</span>
  <span *ngIf="errors['phoneInvalid']">Phone number format is invalid. Valid characters: 0-9, (, ), -</span>
  <!-- Password -->
  <span *ngIf="errors['passwordWeak']">Password is too weak</span>
  <span *ngIf="errors['passwordInvalid']">Password format is invalid</span>
  <span *ngIf="errors['passwordMismatch']">Passwords don't match</span>
  <!-- Min/max -->
  <span *ngIf="errors['min']">Value should be greater than {{ errors['min'].min }}</span>
  <span *ngIf="errors['max']">Value should not be greater than {{ errors['max'].max }}</span>
  <span *ngIf="errors['greaterThan']">{{errors['greaterThan']}}</span>
  <!-- Date -->
  <span *ngIf="errors['minDate']">{{ errors['minDate'] }}</span>
  <span *ngIf="errors['dateRangeStart']">The date should not be before {{errors['dateRangeStart'] | date}}</span>
  <span *ngIf="errors['dateRangeEnd']">The date should not be after {{errors['dateRangeEnd'] | date}}</span>

  <!-- Max symbols -->
  <span *ngIf="errors['maxlength']">Amount of characters must not exceed {{errors['maxlength'].requiredLength}}</span>
  <span *ngIf="errors['minlength']">Amount of characters must be at least {{errors['minlength'].requiredLength}}</span>
  <!-- String length -->
  <span *ngIf="errors['requiredLength']">Invalid length. {{errors['requiredLength']}} symbols required</span>
  <!-- File -->
  <span *ngIf="errors['fileMaxSize']">File is too big. Maximum file size is {{ errors['fileMaxSize'].maxSize | fileSizeConvert }}</span>
  <span *ngIf="errors['fileExtensions']">The type of the file is invalid. Allowed file types: {{ errors['fileExtension'].allowedExtensions }}</span>
  <!-- Less than fields comparison -->
  <span *ngIf="errors['lessThanValidation']">
    {{ errors['lessThanValidation'].secondControl.alias || (errors['lessThanValidation'].secondControl.name | decamelize) }}
    should be greater than
    {{ errors['lessThanValidation'].firstControl.alias || (errors['lessThanValidation'].firstControl.name | decamelize) }}
  </span>
  <!-- Other -->
  <span *ngIf="errors['notFromList']">Please select a value from the list</span>
  <!-- Date -->
  <span *ngIf="errors['notCurrentFinYear']">Date is out of the current financial year</span>
  <span *ngIf="errors['matDatepickerMin']">Date should be greater than {{ errors['matDatepickerMax'].min | date }}</span>
  <span *ngIf="errors['matDatepickerMax']">Date should not be greater than {{ errors['matDatepickerMax'].max | date }}</span>
  <!-- Properties -->
  <span *ngIf="errors['addressIsUsed']">This address is already active in your portfolio!</span>
  <!-- Fields summary -->
  <span *ngIf="errors['fieldsSum']">
    The sum of the '{{ errors['fieldsSum'].alias || (errors['fieldsSum'].name | decamelize) }}' fields exceeds {{ errors['fieldsSum'].summary }}
  </span>
  <!-- Common sum validator -->
  <span *ngIf="errors['commonSum']">
    {{errors['commonSum']}}
  </span>
  <!-- Required -->
  <span *ngIf="errors['required']">Field is required</span>
  <!-- string -->
  <span *ngIf="errors['alphaSpace']">Please use only letters</span>
</ng-container>
